import DataReadyOrders from './DataReadyOrders';
import PostbackResend from './PostbackResend';

const routes = [
	{
		path: '/dataready-orders',
		component: { template: '<router-view/>' },
		children: [
			{
				name: 'dataready-orders.list',
				path: 'list',
				component: DataReadyOrders
			}
		]
	},
	{
		path: '/postback',
		component: { template: '<router-view/>' },
		children: [
			{
				name: 'postback.resend',
				path: 'resend',
				component: PostbackResend
			}
		]
	}
];

export default routes;

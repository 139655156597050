import Vue from 'vue';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'controlCentrePostback',
		basePath: 'postback',
		pluralName: 'postback',
		options: {
			namespaced: true,
			actions: {
				find: {
					dataProp: 'data', // -> `data` in the response payload
					countProp: 'total' // -> `total` in the response payload
				}
			}
		},
		transport: 'controlCentreApi',
		state: {
			triggerSources: [],
			resendResults: []
		},
		actions,
		mutations,
		getters
	});
}

import store from '../vuex';

const $t = str => str;

const isFeatureFlagEnabled = featureName => store.getters?.['featureToggle/features']?.[featureName];

const menuStructure = [
	{
		title: $t('Fulfilment'),
		name: 'admin.fulfilments',
		visible: () => store.getters['controlCentre/aclGroups']['fulfilment-admin'],
		matches: [
			{ name: 'admin.materials.create' },
			{ name: 'admin.materials.view' },
			{ name: 'admin.countries.create' },
			{ name: 'admin.countries.view' },
			{ name: 'admin.priceConfigurations.create' },
			{ name: 'admin.priceConfigurations.view' },
			{ name: 'admin.hp-spec-templates.create' },
			{ name: 'admin.hp-spec-templates.view' },
			{ name: 'admin.currencies.create' },
			{ name: 'admin.currencies.view' },
			{ name: 'admin.tariffs.create' },
			{ name: 'admin.tariffs.view' },
			{ name: 'admin.shipping.create' },
			{ name: 'admin.shipping.view' },
			{ name: 'admin.fulfilments.view' },
			{ name: 'admin.fulfilments.item.view' },
			{ name: 'admin.fulfilments.shipment.view' },
			{ name: 'admin.fees.create' },
			{ name: 'admin.fees.view' },
			{ name: 'admin.discountProfiles' },
			{ name: 'admin.discountProfiles.view' },
			{ name: 'admin.discountProfiles.create' }
		],
		items: [
			{
				title: $t('Support'),
				items: [
					{
						title: $t('Fulfilments'),
						name: 'admin.fulfilments',
						query: { filters: { status: ['cancelled', 'error', 'submissionError'] } }
					}
				]
			},
			{
				title: $t('Global Specs'),
				items: [
					{ title: $t('Spec Templates'), name: 'admin.hp-spec-templates' },
					{ title: $t('Materials'), name: 'admin.materials' }
				]
			},
			{
				title: $t('Management'),
				items: [
					{ title: $t('Price Configurations'), name: 'admin.priceConfigurations' },
					{ title: $t('Currencies'), name: 'admin.currencies' },
					{ title: $t('Countries'), name: 'admin.countries' },
					{ title: $t('HS Codes (Tax)'), name: 'admin.tariffs' },
					{ title: $t('Shipping'), name: 'admin.shipping' },
					{ title: $t('Additional Fees'), name: 'admin.fees' },
					{ title: $t('Discount Profiles'), name: 'admin.discountProfiles' }
				]
			}
		]
	},
	{
		title: $t('Features'),
		name: 'admin.features.migration.overview',
		visible: () => store.getters['controlCentre/aclGroups']['cs-team-member'],
		matches: [{ name: 'admin.features.migration.overview' }],
		items: [
			{
				title: $t('Site Flow'),
				items: [
					{
						title: $t('Migration Overview'),
						name: 'admin.features.migration.overview'
					}
				]
			}
		]
	},
	{
		title: $t('Accounts'),
		name: 'accounts.list',
		visible: () => store.getters['controlCentre/aclGroups']['cs-team-member'],
		matches: [
			{ name: 'accounts.edit' },
			{ name: 'deviceType.add' },
			{ name: 'deviceType.edit' },
			{ name: 'productType.add' },
			{ name: 'productType.edit' },
			{ name: 'role.add' },
			{ name: 'role.edit' },
			{ name: 'transformer.add' },
			{ name: 'transformer.edit' }
		],
		items: [
			{
				title: $t('Manage'),
				items: [
					{
						title: $t('Accounts'),
						name: 'accounts.list',
						matches: [{ name: 'accounts.edit' }]
					},
					{
						title: $t('Linked Accounts'),
						name: 'linked-accounts.list'
					},
					{
						title: $t('Device Types'),
						name: 'deviceType.list'
					},
					{
						title: $t('Product Types'),
						name: 'productType.list'
					},
					{
						title: $t('Roles'),
						name: 'role.list'
					},
					{
						title: $t('Transformers'),
						name: 'transformer.list'
					},
					{
						title: $t('Access Controller'),
						name: 'access-controller'
					}
				]
			}
		]
	},
	{
		title: $t('Support Tools'),
		name: 'dataready-orders.list',
		visible: () => store.getters['controlCentre/aclGroups']['cs-team-member'],
		matches: [{ name: 'dataready-orders.list' }],
		items: [
			{
				title: $t('Tools'),
				items: [
					{
						title: $t('Data Ready Orders'),
						name: 'dataready-orders.list'
					},
					{
						title: $t('Postback Resend'),
						name: 'postback.resend'
					}
				]
			}
		]
	}
];

export default menuStructure;

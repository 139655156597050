<template>
	<DefaultLayout>
		<ofs-panel>
			<section v-if="isLoading">
				<Loader />
			</section>
			<section v-else class="col-4">
				<h1>{{ $t('Postback Resend Tool') }}</h1>
				<section>
					<b-alert v-model="showSuccess" variant="success" dismissible>
						<p>
							{{ $t('Postbacks for the following resources were sent successfully: ') }}
							<ul>
								<li v-for="result in succcessfulResults" :key="result.notificationId">
									{{ `${$t('Resource')}: ${result.resourceId}, ${$t('Trigger')}:
									 ${result.triggerId}, ${$t('Notification created')}: ${result.notificationId}` }}
								</li>
							</ul>
						</p>
					</b-alert>
					<b-alert v-model="showErrors" variant="danger" dismissible>
						<p>
							{{ $t('Postbacks for the following resources failed: ') }}
							<ul>
								<li v-for="result in errorResults" :key="result.notificationId">
									{{ `${$t('Resource')}: ${result.resourceId}, ${$t('Trigger')}:
									 ${result.triggerId || '-'}. ${$t('Error')}: ${result.error}` }}
								</li>
							</ul>
						</p>
					</b-alert>
				</section>
				<b-form class="mt-3">
					<b-row>
						<b-col>
							<of-multi-select
								name="triggerSourceId"
								:label="$t('Trigger source')"
								:horizontal="false"
								:allow-clear="false"
								data-test-id="triggerSource"
								track-by="value"
								:options="triggerSourcesOptions"
								:placeholder="$t('Trigger source')"
							/>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<of-form-input
								class="mb-0"
								name="resources"
								:label="$t('Resource id')"
								required
								:description="
									$t('Introduce the resource id, or a list of resource ids separated by commas.')
								"
							/>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<br />
							<of-submit-button @click.prevent="resend">{{ $t('Resend') }}</of-submit-button>
						</b-col>
					</b-row>
				</b-form>
			</section>
		</ofs-panel>
	</DefaultLayout>
</template>

<script>
import _ from 'lodash';
import DefaultLayout from '../../components/DefaultLayout';
import Loader from '../../components/Loader';
import { displayError } from '../../lib/helpers';
import { OfFormInput, OfMultiSelect, OfsPanel, OfSubmitButton, withForm } from '@workflow-solutions/ofs-vue-layout';
import { mapActions, mapGetters } from 'vuex';

const supportedResources = ['orders', 'subbatch', 'orderSubmissionError', 'shipments'];

export default {
	name: 'PostbackResend',
	components: {
		DefaultLayout,
		OfsPanel,
		Loader,
		OfMultiSelect,
		OfFormInput,
		OfSubmitButton
	},
	mixins: [withForm('PostbackResend')],
	data() {
		return {
			isLoading: false,
			showErrors: false,
			showSuccess: false
		};
	},
	computed: {
		...mapGetters({
			triggerSources: 'controlCentrePostback/triggerSources',
			resendResults: 'controlCentrePostback/resendResults'
		}),
		triggerSourcesOptions() {
			const filtered = _.filter(this.triggerSources || [],
				ts => ts.systems.includes('siteflow') && supportedResources.includes(ts.resource));
			return _.map(filtered, ts => ({ text: ts.name, value: ts._id  }));
		},
		succcessfulResults() {
			return _.filter(this.resendResults || [], r => !r.error);
		},
		errorResults() {
			return _.filter(this.resendResults || [], r => r.error);
		}
	},
	async mounted() {
		await this.fetchData();
	},
	methods: {
		...mapActions({
			fetchTriggerSources: 'controlCentrePostback/fetchTriggerSources',
			resendPostback: 'controlCentrePostback/resendPostback'
		}),
		async fetchData() {
			this.isLoading = true;
			try {
				await this.fetchTriggerSources();
				this.initFormData({ triggerSourceId: this.triggerSourcesOptions[0]?.value });
			} catch (err) {
				this.$notify({ type: 'error', text: displayError(err) });
			} finally {
				this.isLoading = false;
			}
		},
		async resend() {
			this.showErrors = false;
			this.showSuccess = false;
			this.isLoading = true;

			try {
				await this.resendPostback({ requestBody: {
					triggerSourceId: this.formData.triggerSourceId,
					resourceIds: this.formData.resources.split(',').map(r => r.trim())
				}});
				this.showSuccess = !!this.succcessfulResults.length;
				this.showErrors = !!this.errorResults.length;
			} catch (err) {
				this.$notify({ type: 'error', text: displayError(err) });
			}
			finally {
				this.isLoading = false;
			}
		}
	}
};
</script>

<style></style>

import _ from 'lodash';

export default {
	fetchTriggerSources: async ({ commit, dispatch }) => {
		const { data } = await dispatch('request', {
			method: 'GET',
			path: 'postback/trigger-sources'
		});
		commit('SET_TRIGGER_SOURCES', data.data);
	},
	resendPostback: async ({ commit, dispatch }, { requestBody }) => {
		const { data } = await dispatch('request', {
			method: 'POST',
			path: 'postback/create',
			payload: requestBody
		});
		commit('SET_POSTBACK_RESEND_RESULTS', data.data);
	}
};
